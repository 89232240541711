import AccelerationValuesData from "./dummy-db/AccelerationValues.json"
import AxialVibrationValuesData from "./dummy-db/AxialVibrationValues.json"
import RadialVibrationValuesData from "./dummy-db/RadialVibrationValues.json"
import RadialAxisKurtosisValuesData from "./dummy-db/RadialAxisKurtosisValues.json"
import TemperatureValuesData from "./dummy-db/TemperatureValues.json"

let globalDate = new Date();

function customizeDates() {
    let arrSize = AccelerationValuesData.length
    const initialDate = new Date();
    globalDate = initialDate;
    let currentDate = initialDate;
    let timezoneOffset = initialDate.getTimezoneOffset() * 60000
    let minutes = 0;
    for (let i = arrSize - 1; i >= 0; i--) {
        currentDate = new Date(initialDate.getTime() + minutes * 60000 - timezoneOffset);
        if (AccelerationValuesData[i])
            AccelerationValuesData[i].Time = currentDate
        if (AxialVibrationValuesData[i])
            AxialVibrationValuesData[i].Time = currentDate
        if (RadialVibrationValuesData[i])
            RadialVibrationValuesData[i].Time = currentDate
        if (RadialAxisKurtosisValuesData[i])
            RadialAxisKurtosisValuesData[i].Time = currentDate
        if (TemperatureValuesData[i])
            TemperatureValuesData[i].Time = currentDate
        minutes--
    }

}
customizeDates();
export default {
    namespaced: true,
    state: {
        isSimulationRunning: false,
        AccelerationValues: AccelerationValuesData,
        AxialVibrationValues: AxialVibrationValuesData,
        RadialVibrationValues: RadialVibrationValuesData,
        RadialAxisKurtosisValues: RadialAxisKurtosisValuesData,
        TemperatureValues: TemperatureValuesData,
    },
    getters: {
        getSimulationStatus(state) {
            return state.isSimulationRunning;
        },
        getAccelerationValuesData(state) {
            return state.AccelerationValues
        },
        getAxialVibrationValuesData(state) {
            return state.AxialVibrationValues
        },
        getRadialVibrationValuesData(state) {
            return state.RadialVibrationValues
        },
        getRadialAxisKurtosisValuesData(state) {
            return state.RadialAxisKurtosisValues
        },
        getTemperatureValuesData(state) {
            return state.TemperatureValues
        }
    },
    actions: {
        async StartSimulation({ commit, state }, simulationCtx) {

            let dataTypesToBeInserted = {}
            let allWorkcenters = ["L101", "L102", "L103", "L104", "L105", "L106", "L107", "L108"]
            let simulationInfo = JSON.parse(JSON.stringify(simulationCtx.data))
            let date = globalDate;
            let timezoneOffset = date.getTimezoneOffset() * 60000
            const simulationData = JSON.parse(JSON.stringify(simulationCtx.data));
            state.isSimulationRunning = true;
            //duration and value inputs must be number dont forget to change that.
            for (let i = 0; i < simulationCtx.maxDuration; i++) {
                const elapsedTimeMilliseconds = (i + 1) * 60000 // translation in minutes
                await new Promise((resolve) => {
                    setTimeout(() => {
                        for (let type in simulationInfo) {
                            let wcToBeInserted = { Time: new Date(date.getTime() + elapsedTimeMilliseconds - timezoneOffset) }
                            for (let workcenter of allWorkcenters) {
                                if (simulationInfo[type][workcenter] && simulationInfo[type][workcenter].duration > 0) {
                                    let wcObject = simulationData[type][workcenter]
                                    let calculatedValue = wcObject.currentValue + ((wcObject.targetValue - wcObject.currentValue) / wcObject.duration) * (i + 1)
                                    wcToBeInserted[workcenter] = Number((Math.floor(calculatedValue * 100) / 100).toFixed(2))
                                    simulationInfo[type][workcenter].duration--
                                }
                                else {
                                    let typeArr = state[`${type}Values`]
                                    let calculatedValue = state[`${type}Values`][typeArr.length - 1][workcenter]
                                    wcToBeInserted[workcenter] = calculatedValue
                                }
                            }
                            dataTypesToBeInserted[type] = wcToBeInserted
                        }
                        commit("insertValues", { data: dataTypesToBeInserted })
                        dataTypesToBeInserted = {};
                        resolve("")
                    }, 3200)
                })
            }
            state.isSimulationRunning = false;
        }
    },
    mutations: {
        insertValues(state, simulationData) {
            for (let type in simulationData.data) {
                state[`${type}Values`] = state[`${type}Values`].concat(simulationData.data[type])
            }
        }
    },
}
